import { Typography } from "@mui/material";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { ArrayParam, BooleanParam, ObjectParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import { GetPolicyTuningConfigParamsResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import Dialog from "../../../components/Dialog";
import SnapshotWrapper from "../../../components/SnapshotWrapper";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../../components/Tab";
import WorkloadEventPopup from "../../../components/WorkloadStatusByNamespace/WorkloadEventsPopup";
import { getDisplayWorkloadName } from "../../../components/WorkloadStatusByNamespace/WorkloadStatusByNamespace";
import useHpaOptimizationEnabled from "../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import { enableFilterByUrlParam, FilterByUrlParam } from "../../../utils/queryParams";
import { SELECTED_CONTAINER_KEY } from "./ContainerFilter";
import { EventPoint } from "./Diagnostics/utils";
import PolicyTuningContainer from "./PolicyTuningContainer";
import PolicyTuningDialogueTitle from "./PolicyTuningDialogueTitle";
import PolicyTuningHpa from "./PolicyTuningHpa";
import { HpaChartComponent } from "./UsageChart/UsageHpaChart";
import WorkloadAnalytics from "./WorkloadAnalytics/WorkloadAnalytics";
import WorkloadNetwork from "./WorkloadNetwork/WorkloadNetwork";
import {
  ChartComponents,
  Policy,
  POLICY_TUNING_DATES_URL_PARAM,
  PolicyTuningTabs,
  useViewPeriodQueryParams,
  ViewPeriodOptions,
} from "./utils";

export const POLICY_TUNING_SELECTED_TAB_QUERY_KEY = "policyTuningSelectedTab";
const HAS_WORKLOAD_ANALYTICS_TAB = true;
const HAS_WORKLOAD_NETWORK_TAB = true;
const MIN_TAB_CONTENT_HEIGHT = 608;

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  setSelectedWorkload: (workload: components["schemas"]["UtilsWorkload"] | undefined) => void;
  isOpen: boolean;
  onClose: () => void;
  fetchWorkloads: () => void;
  hideWorkloadSuffix?: boolean;
  displayName?: string;
}

const PolicyTuning = ({
  selectedWorkload,
  isOpen,
  onClose,
  fetchWorkloads,
  hideWorkloadSuffix,
  displayName,
  setSelectedWorkload,
}: Props) => {
  const hpaOptimizationEnabled = useHpaOptimizationEnabled();
  const ENABLE_HPA_RECOMMENDATION =
    enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION) && selectedWorkload.hasHpa;
  const HAS_HPA_TAB = ENABLE_HPA_RECOMMENDATION || hpaOptimizationEnabled;

  const [isAutomate, setIsAutomate] = useState<boolean>(selectedWorkload.auto);

  const [, setDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [, setSelectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY, StringParam);
  const [workloadName, setWorkloadName] = useState<string | undefined>(undefined);
  const [isHpaFilterApplied] = useQueryParam("hasHpa", BooleanParam);
  const [isOptimizationTypeApplied] = useQueryParam("hpaOptimizationType", ArrayParam);

  const [selectedTab, setSelectedTab] = useQueryParam(
    POLICY_TUNING_SELECTED_TAB_QUERY_KEY,
    withDefault(StringParam, PolicyTuningTabs.Resources)
  );

  const [viewPeriod, setSelectedViewPeriod] = useViewPeriodQueryParams();
  const [tuningParams, setTuningParams] = useState<GetPolicyTuningConfigParamsResponse>({
    cpuPolicyTuningParams: undefined,
    memoryPolicyTuningParams: undefined,
  });

  const [selectedPolicy, setSelectedPolicy] = useState<Policy | undefined>({
    name: selectedWorkload.policyName,
    displayName: selectedWorkload.displayPolicyName,
  });

  const [selectedChartComponents, setSelectedChartComponents] = useState<ChartComponents[]>([
    ChartComponents.AverageUsage,
    ChartComponents.RecommendedRequest,
    ChartComponents.CurrentRequest,
  ]);

  const [selectedHpaChartComponents, setSelectedHpaChartComponents] = useState<HpaChartComponent[]>([]);

  const [cpuCappingConfig, setCpuCappingConfig] = useState<
    components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined
  >(undefined);

  const [memoryCappingConfig, setMemoryCappingConfig] = useState<
    components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined
  >(undefined);

  const [emptyEventArray, setEmptyEventArray] = useState<EventPoint[] | undefined>(undefined);

  useEffect(() => {
    if (!viewPeriod) {
      setSelectedViewPeriod(ViewPeriodOptions["1 day"]);
    }
  }, []);

  const handleClose = () => {
    setDates(undefined);
    setSelectedContainer(undefined);
    setSelectedTab(undefined);
    onClose();
  };

  useEffect(() => {
    if (isHpaFilterApplied || (isOptimizationTypeApplied && isOptimizationTypeApplied.length > 0))
      setSelectedTab(PolicyTuningTabs.Hpa);
  }, [isHpaFilterApplied, isOptimizationTypeApplied]);

  useEffect(() => {
    if (selectedWorkload) {
      const workloadDisplayName = displayName ?? selectedWorkload.workloadName;
      setWorkloadName(getDisplayWorkloadName(`${selectedWorkload.type}: ${selectedWorkload.namespace}/${workloadDisplayName}`, hideWorkloadSuffix));
    }
    if (selectedWorkload.isReadyRecommendation === false) {
      handleClose();
    }
  }, [selectedWorkload, displayName, hideWorkloadSuffix]);

  const recommendationName = selectedWorkload.type.toLowerCase() + "-" + selectedWorkload.workloadName;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={
        <PolicyTuningDialogueTitle
          workloadName={workloadName}
          name={selectedWorkload.workloadName}
          namespace={selectedWorkload.namespace}
          kind={selectedWorkload.type}
        />
      }
      dialogContentStyle={{
        padding: "0px",
      }}
      minWidth="1200px"
    >
      <div className="flex flex-col gap-[10px] pt-[10px] bg-background-chipHover">
        <div className="mt-[6px] pt-[30px]">
          <div className="bg-white px-[20px] py-[20px] relative">
            <div className={clsx(TABS_CONTAINER_CLASS_NAME, "w-full top-[-36px] left-0")}>
              {Object.entries(PolicyTuningTabs).map(([key, value]) => {
                if (value === PolicyTuningTabs.Analytics && !HAS_WORKLOAD_ANALYTICS_TAB) return null;
                if (value === PolicyTuningTabs.Network && !HAS_WORKLOAD_NETWORK_TAB) return null;

                let isDisabled = false;
                let tooltipContent: undefined | React.ReactNode = undefined;

                if (value === PolicyTuningTabs.Hpa) {
                  switch (true) {
                    case !selectedWorkload?.hasHpa:
                      isDisabled = true;
                      tooltipContent = (
                        <Typography variant="caption">
                          HPA optimization is enabled only for workloads with an attached HPA.{" "}
                          <b>No HPA was found for this workload</b>.
                        </Typography>
                      );
                      break;
                    case selectedWorkload?.type !== "Deployment":
                      isDisabled = true;
                      tooltipContent = (
                        <Typography variant="caption">
                          HPA optimization is currently supported only for <b>Deployments</b>. The current workload type
                          is: <b>{selectedWorkload?.type}</b>.
                        </Typography>
                      );
                      break;
                    default:
                      break;
                  }
                }

                return (
                  <SnapshotWrapper
                    wrappedType="tab"
                    inactive={
                      value !== PolicyTuningTabs.Hpa ||
                      (value === PolicyTuningTabs.Hpa && HAS_HPA_TAB) ||
                      selectedTab === PolicyTuningTabs.Hpa
                    }
                  >
                    <Tab
                      key={key}
                      isSelected={selectedTab === value}
                      onClick={() => {
                        setSelectedTab(value);
                      }}
                      name={value}
                      dataTestId={`policy-tuning-${key}-tab`}
                      disabled={isDisabled}
                      tooltipContent={tooltipContent}
                      hasSelectedUnderline
                    />
                  </SnapshotWrapper>
                );
              })}
            </div>
            {selectedTab === PolicyTuningTabs.Resources && (
              <PolicyTuningContainer
                selectedWorkload={selectedWorkload}
                fetchWorkloads={fetchWorkloads}
                tuningParams={tuningParams}
                setTuningParams={setTuningParams}
                selectedPolicy={selectedPolicy}
                setSelectedPolicy={setSelectedPolicy}
                selectedChartComponents={selectedChartComponents}
                setSelectedChartComponents={setSelectedChartComponents}
                cpuCappingConfig={cpuCappingConfig}
                setCpuCappingConfig={setCpuCappingConfig}
                memoryCappingConfig={memoryCappingConfig}
                setMemoryCappingConfig={setMemoryCappingConfig}
                emptyEventArray={emptyEventArray}
                setEmptyEventArray={setEmptyEventArray}
                isAutomate={isAutomate}
                setIsAutomate={setIsAutomate}
              />
            )}
            {selectedTab === PolicyTuningTabs.Hpa && (
              <>
                <SnapshotWrapper inactive={HAS_HPA_TAB}>
                  <PolicyTuningHpa
                    selectedWorkload={selectedWorkload}
                    setWorkloadName={setWorkloadName}
                    fetchWorkloads={fetchWorkloads}
                    tuningParams={tuningParams}
                    setTuningParams={setTuningParams}
                    selectedPolicy={selectedPolicy}
                    setSelectedPolicy={setSelectedPolicy}
                    selectedChartComponents={selectedHpaChartComponents}
                    setSelectedChartComponents={setSelectedHpaChartComponents}
                    isAutomate={isAutomate}
                    setIsAutomate={setIsAutomate}
                  />
                </SnapshotWrapper>
              </>
            )}
            {selectedTab === PolicyTuningTabs.Events && (
              <div style={{ minHeight: MIN_TAB_CONTENT_HEIGHT }}>
                <WorkloadEventPopup
                  namespace={selectedWorkload.namespace}
                  name={recommendationName}
                  className="mt-[0px] py-[20px]"
                />
              </div>
            )}
            {selectedTab === PolicyTuningTabs.Analytics && (
              <div style={{ minHeight: MIN_TAB_CONTENT_HEIGHT }}>
                <WorkloadAnalytics
                  selectedWorkload={selectedWorkload}
                  policyName={selectedPolicy?.name}
                  selectedChartComponents={selectedChartComponents}
                  setSelectedChartComponents={setSelectedChartComponents}
                  setCpuCappingConfig={setCpuCappingConfig}
                  setMemoryCappingConfig={setMemoryCappingConfig}
                  setEmptyEventArray={setEmptyEventArray}
                />
              </div>
            )}
            {selectedTab === PolicyTuningTabs.Network && (
              <WorkloadNetwork selectedWorkload={selectedWorkload} setSelectedWorkload={setSelectedWorkload} />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default PolicyTuning;
