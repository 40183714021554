import { useFormikContext } from "formik";
import { components } from "../../../../api/schema";
import FormSingleSelect from "../../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import * as policyUtils from "../../../../utils/policyUtils";
import { HPA_SECTION_CLASS_NAME } from "../utils";

const minRreplicaPrectailOptions: [string, number][] = [
  ["60%", 60],
  ["70%", 70],
  ["80%", 80],
  ["90%", 90],
  ["100% (MAX)", 100],
];

const minRreplicaWindowOptions: [string, string][] = [
  ["7 days", "168h"],
  ["14 days", "336h"],
  ["21 days", "504h"],
];

interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
}

const GeneralWorkloadOptimization = ({ isCustomizedPolicy, defaultPolicy }: Props) => {
  const { values } = useFormikContext<{
    hasHPAenabled: boolean;
    workloadsToOptimize: string;
  }>();

  const defaultReplicasPercentile = isCustomizedPolicy
    ? policyUtils.replicasPercentilePercentage(defaultPolicy)
    : undefined;
  const defaultReplicasWindow = isCustomizedPolicy ? policyUtils.replicasWindow(defaultPolicy) : undefined;
  const isDisabled = !values.hasHPAenabled && isCustomizedPolicy;

  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription
        title="Optimize static workloads"
        description="Automate to optimize the minimum replicas based on highest usage within the historical window."
      />
      <div className="flex flex-wrap gap-7">
        <FormSingleSelect
          label="Percentile"
          name="replicasPercentilePercentage"
          options={minRreplicaPrectailOptions}
          areOptionsDisabled={!isCustomizedPolicy}
          defaultValue={defaultReplicasPercentile}
          disabled={isDisabled}
        />
        <FormSingleSelect
          label="Window (Max replicas)"
          name="replicasWindow"
          options={minRreplicaWindowOptions}
          areOptionsDisabled={!isCustomizedPolicy}
          defaultValue={defaultReplicasWindow}
          disabled={isDisabled}
        />
      </div>
    </div>
  );
};

export default GeneralWorkloadOptimization;
