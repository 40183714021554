import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";
import { components } from "../../api/schema";
import { POLICY_TUNING_SELECTED_TAB_QUERY_KEY } from "../../pages/Overview/PolicyTuning/PolicyTuning";
import { PolicyTuningTabs } from "../../pages/Overview/PolicyTuning/utils";
import { getWorkloadType } from "../../utils/namesUtils";
import Tooltip from "../Tooltip";
import { SELECTED_WORKLOAD_OVERVIEW_ID_QUERY_PARAM } from "./overviewUtils";
import { SCALEOPS_ROLLOUT, WorkloadType } from "./utils";
import WorkloadTooltip from "../../utils/workloadTooltip";

interface GetWorkloadNameRowProps {
  name: string;
  namespace: string;
  type: string;
  cluster: string;
  targetTab: PolicyTuningTabs;
  setSelectedWorkload?: (workload: components["schemas"]["UtilsWorkload"] | undefined) => void;
}

export const WorkloadNameRow = ({
  name,
  namespace,
  type,
  cluster,
  targetTab,
  setSelectedWorkload,
}: GetWorkloadNameRowProps) => {
  const navigate = useNavigate();
  const [, setSelectedTab] = useQueryParam(POLICY_TUNING_SELECTED_TAB_QUERY_KEY, StringParam);

  const displayWorkloadName = `${namespace}/${name}`;

  const [, setSelectedWorkloadOverviewId] = useQueryParam(SELECTED_WORKLOAD_OVERVIEW_ID_QUERY_PARAM, StringParam);

  const isScaleOpsRollout = name.startsWith(SCALEOPS_ROLLOUT);

  let workloadType = getWorkloadType(type);

  switch (true) {
    case workloadType === WorkloadType.Family && isScaleOpsRollout:
      workloadType = WorkloadType.AlgoRollout;
      break;
    case workloadType === WorkloadType.Family && !isScaleOpsRollout:
      workloadType = WorkloadType.GenericType;
      break;
    default:
      break;
  }

  return (
    <Tooltip
      title={<WorkloadTooltip namespace={namespace} workloadName={name} type={type} />}
      maxWidth={500}
      className="w-full relative"
    >
      <button
        onClick={() => {
          if (setSelectedWorkload) {
            const link = `${cluster}/${namespace}/${workloadType}/${name}`;
            setSelectedWorkload(undefined);
            setSelectedWorkloadOverviewId(link);
            setSelectedTab(targetTab);
          } else {
            const link = `/?selectedWorkloadOverviewId=${cluster}%2F${namespace}%2F${workloadType}%2F${name}&policyTuningViewPeriod=168&${POLICY_TUNING_SELECTED_TAB_QUERY_KEY}=${targetTab}`;
            navigate(link);
          }
        }}
        className="w-full inline-block truncate text-start hover:underline hover:text-main-linkBlue"
      >
        {displayWorkloadName}
      </button>
    </Tooltip>
  );
};

export default WorkloadNameRow;
