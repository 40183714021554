import clsx from "clsx";
import React, { useEffect } from "react";
import FreezeTooltipWarning from "../../pages/Analytics/AnalyticsV2/Graphs/hooks/FreezeTooltipWarning";
import { TooltipTrigger, UpdateActiveTooltips } from "../../pages/Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../pages/Analytics/AnalyticsV2/Graphs/hooks/utils";
import LinkableWorkloadChartTooltipElement from "../../pages/Analytics/AnalyticsV2/Graphs/LinkableWorkloadChartTooltipElement";
import { DEFAULT_DATE_TIME_FORMAT } from "../../utils/formatterUtils";
import { TOOLTIP_WRAPPER_CLASS_NAME } from "../../utils/styleUtils";
import ChartTooltipTime from "../ChartTooltipTime";
import { ChartElement } from "./utils";
import ChartTooltipItemsCount from "../ChartTooltipItemsCount";

export const SUM = "#sum";
export const QUANTILE = "#quantile";
const MAX_ELEMENTS_PER_TOOLTIP = 10;

interface CustomTooltipProps {
  elements: ChartElement[];
  active?: boolean;
  payload?: { value: string | number; name?: string; dataKey: string; stroke: string }[];
  label?: string;
  selectedChartComponents: string[];
  renderNameFunction: (key: string, elements: ChartElement[]) => string;
  valueFormatter?: (tick: number) => string;
  hasItemsCount?: boolean;
  tooltipId?: string;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  updateActiveTooltips?: UpdateActiveTooltips;
  enableCopyTextOnClick?: boolean;
}

const CustomTooltip = ({
  elements,
  active,
  payload,
  label,
  selectedChartComponents,
  valueFormatter,
  renderNameFunction,
  hasItemsCount,
  tooltipTrigger,
  tooltipId,
  frozenTooltipType,
  updateActiveTooltips,
  enableCopyTextOnClick,
}: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && updateActiveTooltips(String(tooltipId), !!active);
  }, [active]);

  if (active && payload && payload.length) {
    let sortedPayload = payload.sort((a, b) => {
      return String(a.name).localeCompare(String(b.name), undefined, { numeric: true });
    });

    sortedPayload = sortedPayload.sort((a, b) => {
      if (a?.name === SUM) return -1;
      if (b?.name === SUM) return 1;
      if (a?.name?.includes(QUANTILE)) return -1;
      if (b?.name?.includes(QUANTILE)) return 1;
      return Number(b?.value) - Number(a?.value);
    });

    const numberOfPercentileOrSumElements = sortedPayload.filter(
      (item) => item?.name?.includes(QUANTILE) || item?.name === SUM
    ).length;

    const maxItemsToShow = MAX_ELEMENTS_PER_TOOLTIP + numberOfPercentileOrSumElements;

    return (
      <div className={clsx("bg-[rgba(255,255,255,0.9)] pointer-events-auto", TOOLTIP_WRAPPER_CLASS_NAME)}>
        {label && <ChartTooltipTime timestamp={label} timeFormat={DEFAULT_DATE_TIME_FORMAT} />}
        {sortedPayload.slice(0, maxItemsToShow).map((item, index) => {
          if (selectedChartComponents.includes(item.dataKey ?? "")) {
            const renderedName = renderNameFunction(String(item.name), elements);
            return (
              <LinkableWorkloadChartTooltipElement
                key={index}
                color={item?.stroke ?? ""}
                value={valueFormatter ? valueFormatter(Number(item.value)) : item.value}
                label={<div className="max-w-[277px] truncate rtl">{renderedName}</div>}
                rawLabel={String(item.name)}
                disableLink={frozenTooltipType !== FrozenTooltipType.FrozenAndClickable}
                textToCopyOnClick={enableCopyTextOnClick ? renderedName : undefined}
              />
            );
          }
        })}
        {hasItemsCount && <ChartTooltipItemsCount count={sortedPayload.length} maxToShow={maxItemsToShow} />}
        <FreezeTooltipWarning tooltipTrigger={tooltipTrigger} frozenTooltipType={frozenTooltipType} />
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
