import { CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { AxisDomain } from "recharts/types/util/types";
import InfoIcon from "../../../../Icons/InfoIcon";
import { useMainContext } from "../../../../MainContext";
import { GetTopK, GetTopKParams, GetTopKResponse } from "../../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../../colors";
import ChartTooltipItemsCount from "../../../../components/ChartTooltipItemsCount";
import ChartTooltipTitle from "../../../../components/ChartTooltipTitle";
import CustomLegend from "../../../../components/CustomLegend";
import InfoTooltip from "../../../../components/Tooltip";
import LinkableTooltipElement from "../../../../components/common/LinkableTooltipElement";
import useWindowSize from "../../../../components/useWindowSize";
import { getMaxFlatArray } from "../../../../utils/arrayUtils";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../../../utils/formatterUtils";
import { OverviewLinkType } from "../../../../utils/graphUtils";
import {
  DOT_RADIUS,
  DOT_STROKE_WIDTH,
  LINE_CHART_TYPE,
  TOOLTIP_WRAPPER_CLASS_NAME,
} from "../../../../utils/styleUtils";
import useGetTimeoutSeconds, { MIN_TIMEOUT_SECOND } from "../../../../utils/useGetTimeoutSeconds";
import useFilterQueryParams from "../useFilterQueryParams";
import { DateType, getParsedData } from "../utils";
import { ANALYTICS_PAGE_DAILY_SYNC_ID } from "./graphUtils";
import FreezeTooltipWarning from "./hooks/FreezeTooltipWarning";
import { TooltipTrigger, UpdateActiveTooltips } from "./hooks/useFreezeTooltip";
import { FrozenTooltipType } from "./hooks/utils";
import { PayloadItem, SecondaryValueFnc } from "./topKUtils";

const MAX_ELEMENTS_PER_TOOLTIP = 10;
const DEFAULT_MAX_Y_AXIS_DOMAIN = 1.2;
const DEFAULT_SELECTED_ELEMENTS = Infinity;
const HEIGHT = "h-[200px]";
export const SUM = "#sum";
export const QUANTILE = "#quantile";
const { queryFn, queryKey } = GetTopK();

const defaultRenderNameFunction = (name: string | undefined) => {
  switch (true) {
    case name === SUM:
      return "total";
    case name?.includes(QUANTILE):
      return `${String(name?.replace(QUANTILE, ""))}90th-percentile`;
    default:
      return name;
  }
};

interface CustomTooltipProps {
  active?: boolean;
  payload?: PayloadItem[];
  label?: string;
  selectedChartComponents: string[];
  renderNameFunction: (name: string | undefined) => string | undefined;
  tooltipTrigger: TooltipTrigger | undefined;
  valueFormatter?: (tick: string) => string;
  frozenTooltipType?: FrozenTooltipType;
  tooltipId?: string;
  updateActiveTooltips?: UpdateActiveTooltips;
  enableCopyTextOnClick?: boolean;
  targetResource?: OverviewLinkType;
  getSecondaryValue?: SecondaryValueFnc;
  valueTitle?: string;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  selectedChartComponents,
  valueFormatter,
  tooltipTrigger,
  renderNameFunction,
  frozenTooltipType = FrozenTooltipType.Disabled,
  tooltipId,
  updateActiveTooltips,
  enableCopyTextOnClick,
  targetResource = OverviewLinkType.Workload,
  getSecondaryValue,
  valueTitle,
}: CustomTooltipProps) => {
  useEffect(() => {
    updateActiveTooltips && updateActiveTooltips(String(tooltipId), !!active);
  }, [active]);

  if (active && payload && payload.length) {
    let sortedPayload = payload.sort((a, b) => {
      return String(a.name).localeCompare(String(b.name), undefined, { numeric: true });
    });

    sortedPayload = sortedPayload.sort((a, b) => {
      if (a?.name === SUM) return -1;
      if (b?.name === SUM) return 1;
      if (a?.name?.includes(QUANTILE)) return -1;
      if (b?.name?.includes(QUANTILE)) return 1;
      return Number(b?.value) - Number(a?.value);
    });

    const numberOfPercentileOrSumElements = sortedPayload.filter(
      (item) => item?.name?.includes(QUANTILE) || item?.name === SUM
    ).length;

    const maxItemsToShow = MAX_ELEMENTS_PER_TOOLTIP + numberOfPercentileOrSumElements;

    return (
      <div className={clsx("bg-[rgba(255,255,255,0.9)] pointer-events-auto", TOOLTIP_WRAPPER_CLASS_NAME)}>
        {label && <ChartTooltipTitle timestamp={label} valueTitle={valueTitle} />}
        {sortedPayload.slice(0, maxItemsToShow).map((item, index) => {
          if (selectedChartComponents.includes(item.dataKey ?? "")) {
            const renderedName = renderNameFunction(item.name);
            const secondaryValue = getSecondaryValue
              ? getSecondaryValue(item.name, item.value, item?.payload?.secondaryValues)
              : undefined;

            return (
              <LinkableTooltipElement
                key={index}
                color={item?.stroke ?? ""}
                value={valueFormatter ? valueFormatter(String(item.value)) : item.value}
                secondaryValue={secondaryValue}
                label={<div className="max-w-[277px] truncate rtl">{renderedName}</div>}
                rawLabel={String(item.name)}
                disableLink={frozenTooltipType !== FrozenTooltipType.FrozenAndClickable}
                textToCopyOnClick={enableCopyTextOnClick ? renderedName : undefined}
                targetResource={targetResource}
              />
            );
          }
        })}
        <ChartTooltipItemsCount count={sortedPayload.length} maxToShow={maxItemsToShow} />
        <FreezeTooltipWarning tooltipTrigger={tooltipTrigger} frozenTooltipType={frozenTooltipType} />
      </div>
    );
  }

  return null;
};

type Element = {
  key: string;
  label: string;
  color: string;
  tooltipValueColor?: string;
  fill?: string;
  dataKey?: string;
};

interface Props {
  title: React.ReactNode;
  queryParams: GetTopKParams;
  showPercentage?: boolean;
  wrapDivClassName?: string;
  setDate: (date: DateType) => void;
  YAxisDomain?: AxisDomain | undefined;
  noGroupBy?: boolean;
  YAxisFormatter?: (tick: string) => string;
  isMulticluster?: boolean;
  topK?: number;
  withSum?: boolean;
  infoTooltip?: React.ReactNode;
  infoTooltipMaxWidth?: number;
  syncId?: string | null;
  renderNameFunction?: (name: string | undefined) => string | undefined;
  disabledZoom?: boolean;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  updateActiveTooltips?: UpdateActiveTooltips;
  dotted?: boolean;
  enableCopyTextOnClick?: boolean;
  hasLegend?: boolean;
  customLegendBySeparator?: string;
  YAxisType?: "number" | "category";
  yTickLine?: boolean;
  allowDecimals?: boolean;
  getParsedDataFixedValue?: number;
  targetTooltipLink?: OverviewLinkType;
  getSecondaryValue?: SecondaryValueFnc;
  tooltipValueTitle?: string;
}

const TopKMultiLineChart = ({
  title,
  queryParams,
  showPercentage,
  wrapDivClassName,
  setDate,
  YAxisDomain,
  noGroupBy,
  YAxisFormatter = (tick: string) => tick,
  isMulticluster,
  topK,
  withSum,
  infoTooltip,
  infoTooltipMaxWidth = 660,
  syncId = ANALYTICS_PAGE_DAILY_SYNC_ID,
  renderNameFunction = defaultRenderNameFunction,
  disabledZoom,
  tooltipTrigger,
  frozenTooltipType = FrozenTooltipType.Disabled,
  updateActiveTooltips,
  dotted,
  enableCopyTextOnClick,
  hasLegend,
  customLegendBySeparator,
  YAxisType,
  yTickLine,
  allowDecimals,
  getParsedDataFixedValue,
  targetTooltipLink,
  getSecondaryValue,
  tooltipValueTitle,
}: Props) => {
  const { currentCluster, didClusterChanged } = useMainContext();

  const filtersQueryParams = useFilterQueryParams();
  const { width: windowWidth } = useWindowSize();

  const [elements, setElements] = useState<Element[]>([]);
  const [timeoutSeconds, setTimeoutSeconds] = useState<number | undefined>(MIN_TIMEOUT_SECOND);
  const [chartComponents, setChartComponents] = useState<string[]>([]);
  const [selectedChartComponents, setSelectedChartComponents] = useState<string[]>(chartComponents);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [viewPeriodInHours, setViewPeriodInHours] = useState<number>(3 * 24);

  const { data, isLoading, error, isError } = useQuery<GetTopKResponse, Error>({
    queryKey: [
      queryKey,
      queryParams,
      filtersQueryParams,
      isMulticluster ? "multicluster" : currentCluster,
      topK ? `topK-${topK}` : undefined,
      withSum ? "withSum" : undefined,
    ],
    queryFn: () =>
      queryFn({
        ...queryParams,
        multiCluster: isMulticluster,
        ...filtersQueryParams,
        topK,
        withSum,
        timeoutSeconds: timeoutSeconds,
      }),
    refetchInterval: timeoutSeconds ? timeoutSeconds * 1000 : 60 * 5 * 1000,
    enabled: isQueryEnabled,
  });

  const timeoutSecondsValue = useGetTimeoutSeconds({ data, isError, isDisabled: !isMulticluster });

  useEffect(() => {
    if (data?.values) {
      const firstDate = dayjs(data.values[0].timestamp);
      const lastDate = dayjs(data.values[data.values.length - 1].timestamp);
      setViewPeriodInHours(lastDate.diff(firstDate, "hours"));
    }
  }, [queryKey, queryParams, filtersQueryParams, isMulticluster, topK, withSum]);

  useEffect(() => {
    setTimeoutSeconds(timeoutSecondsValue);
  }, [timeoutSecondsValue]);

  useEffect(() => {
    if (data?.values) {
      const uniqueItemNames: string[] = [
        ...new Set(data.values.map((item) => item.values && Object.keys(item.values)).flat()),
      ]
        .filter((item) => item !== undefined)
        .map((item) => String(item))
        .sort((a, b) =>
          a.startsWith(SUM) || a.includes(QUANTILE) ? -1 : b.startsWith("#") ? 1 : Number(a) - Number(b)
        );
      setChartComponents(uniqueItemNames);
      setSelectedChartComponents(uniqueItemNames.slice(0, DEFAULT_SELECTED_ELEMENTS));
      setElements(
        uniqueItemNames.map((item, index) => {
          const color = SCALEOPS_COLORS.randomColors[index] ?? "#" + Math.random().toString(16).slice(2, 8);
          return {
            key: item,
            label: item,
            color,
          };
        })
      );
    }
  }, [data]);

  const legendComponentStyle: { [key: string]: { color: string } } = {};

  elements.forEach((element) => {
    legendComponentStyle[element.key] = {
      color: element.color,
    };
  });

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  useEffect(() => {
    if (isMulticluster && !!data) {
      setIsQueryEnabled(false);
    }
  }, [data]);

  useEffect(() => {
    setIsQueryEnabled(true);
  }, [queryParams, filtersQueryParams]);

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPointEpoch || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPointEpoch || 0);
      setDate({ from: from, to: to, range: "" });
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    window.addEventListener("mouseup", setDateRage);
    return () => {
      window.removeEventListener("mouseup", setDateRage);
    };
  }, [selectPosition, setDateRage]);

  if (isLoading) {
    return (
      <div
        className={clsx(
          HEIGHT,
          "bg-white border border-border rounded min-h-full w-full flex justify-center items-center"
        )}
      >
        <CircularProgress />
      </div>
    );
  }

  if (error && didClusterChanged) {
    toast.error(`${queryParams?.queryKey ?? ""} chart could not load as for this cluster`);
    console.log("error loading chart", title, error);
  }

  if (isError) {
    console.log(error);
    return null;
  }

  const graphData = getParsedData(
    data,
    showPercentage,
    false,
    queryParams.from ? Number(queryParams.from) : undefined,
    queryParams.to ? Number(queryParams.to) : undefined,
    undefined,
    noGroupBy,
    false,
    true,
    getParsedDataFixedValue
  );

  const maxDataPoint = Math.round(
    getMaxFlatArray(
      graphData.map((item) => {
        return Object.values(item)
          .filter((value) => !Number.isNaN(Number(value)))
          .map((value) => Number(value));
      })
    ) * DEFAULT_MAX_Y_AXIS_DOMAIN
  );

  const firstXPointString = String(graphData[0]?.date);
  const firstXPointEpoch = dayjs(firstXPointString).unix() * 1000;
  let lastXPointString = graphData && String(graphData[graphData.length - 1]?.date);
  lastXPointString = lastXPointString && `${lastXPointString} GMT`;
  const lastXPointEpoch = dayjs(lastXPointString).unix() * 1000;

  return (
    <div
      className={clsx(wrapDivClassName, "bg-white min-h-full border border-border rounded unselectable-svg-text", {
        "pt-8 pb-4": hasLegend,
        "py-8 pr-8": !hasLegend,
      })}
    >
      <div className={HEIGHT}>
        <Typography variant="body2" className="w-full flex justify-center items-center gap-1">
          {title}
          {infoTooltip && (
            <InfoTooltip title={infoTooltip} placement="top" maxWidth={infoTooltipMaxWidth}>
              <InfoIcon width={14} height={14} />
            </InfoTooltip>
          )}
        </Typography>
        <ResponsiveContainer width="100%" height="100%" className="pr-4">
          <ComposedChart
            syncId={syncId ? syncId : undefined}
            data={graphData}
            onMouseDown={(e) => {
              e.activeLabel &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  from: dayjs(`${String(e.activeLabel)}`).unix() * 1000,
                  fromX: e.activeLabel,
                });
            }}
            onMouseMove={(e) => {
              selectPosition?.from &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  to: dayjs(`${String(e.activeLabel)}`).unix() * 1000,
                  toX: e.activeLabel,
                });
            }}
            onMouseLeave={() => {
              if (selectPosition?.from && selectPosition?.to) {
                if (selectPosition?.from < selectPosition?.to) {
                  setSelectPosition({
                    ...selectPosition,
                    to: lastXPointEpoch,
                    toX: lastXPointString,
                  });
                } else {
                  setSelectPosition({
                    to: selectPosition.from,
                    toX: selectPosition.fromX,
                    from: firstXPointEpoch,
                    fromX: firstXPointString,
                  });
                }
              }
            }}
          >
            <CartesianGrid strokeDasharray="4 4" opacity={0.4} />
            <Tooltip
              content={
                <CustomTooltip
                  selectedChartComponents={selectedChartComponents}
                  valueFormatter={YAxisFormatter}
                  renderNameFunction={renderNameFunction}
                  frozenTooltipType={frozenTooltipType}
                  tooltipTrigger={tooltipTrigger}
                  tooltipId={queryKey}
                  updateActiveTooltips={updateActiveTooltips}
                  enableCopyTextOnClick={enableCopyTextOnClick}
                  targetResource={targetTooltipLink}
                  getSecondaryValue={getSecondaryValue}
                  valueTitle={tooltipValueTitle}
                />
              }
              trigger={tooltipTrigger}
              wrapperStyle={{
                outline: "none",
              }}
            />
            <XAxis
              dataKey="date"
              style={{ fontSize: "x-small" }}
              interval={Math.floor(graphData.length / (Number(windowWidth) / 300))}
              strokeWidth={2}
              tickFormatter={(value) => {
                const epochValue = dayjs(String(value)).unix();
                return getTimeFormatFromEpochAndPeriodInHours(epochValue, viewPeriodInHours);
              }}
            />
            <YAxis
              style={{ fontSize: "x-small" }}
              domain={YAxisDomain ?? [0, maxDataPoint || Infinity]}
              type={YAxisType}
              strokeWidth={2}
              tickFormatter={(tick) => YAxisFormatter(String(tick))}
              tickLine={yTickLine}
              allowDecimals={allowDecimals}
            />
            {elements.map((element) => {
              const elementData = elements.find((e) => e.key === element.key);
              if (!elementData || !selectedChartComponents.includes(elementData.key)) return null;
              const { key, label, color, fill } = elementData;

              return (
                <Area
                  type={LINE_CHART_TYPE}
                  strokeWidth={dotted ? 0 : key === SUM ? 2 : 2} //@barel22 keep in case we want to change the SUM in the future
                  dataKey={key}
                  name={label}
                  stroke={color}
                  fill={fill}
                  fillOpacity={fill ? 0.4 : 0}
                  radius={dotted ? DOT_RADIUS : 0}
                  dot={{ r: dotted ? 1 : 0, strokeWidth: dotted ? DOT_STROKE_WIDTH : 0 }}
                  isAnimationActive={!dotted}
                />
              );
            })}
            {selectPosition?.fromX && selectPosition?.toX ? (
              <ReferenceArea
                x1={selectPosition?.fromX}
                x2={selectPosition?.toX}
                stroke="#3B8BFF"
                fill="#3B8BFF"
                fillOpacity={0.3}
                strokeOpacity={0.3}
              />
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      {hasLegend && (
        <div className="w-full flex flex-col justify-center items-end">
          <div className="w-[100%] px-[5%] max-h-[100px] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chip scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md mt-8">
            <CustomLegend<string>
              selectedChartComponents={selectedChartComponents}
              setSelectedChartComponents={setSelectedChartComponents}
              componentStyle={legendComponentStyle}
              ChartComponents={chartComponents
                .map((component) => ({
                  [component]: component,
                }))
                .reduce((a, b) => ({ ...a, ...b }), {})}
              renderNameFunction={(key) => renderNameFunction(key) || ""}
              className="-mt-1"
              fontWeight={500}
              fontSpanClassName="truncate"
              hasTooltip
            />
          </div>
        </div>
      )}
      {!!customLegendBySeparator && (
        <div className="w-full flex flex-col justify-center items-end">
          <div className="w-[100%] px-[5%] max-h-[100px] overflow-y-auto scrollbar-thin scrollbar-thumb-background-chip scrollbar-track-guideline-lightGray scrollbar-thumb-rounded-md scrollbar-track-rounded-md mt-8">
            <CustomLegend<string>
              selectedChartComponents={[
                ...new Set(selectedChartComponents.map((e) => e.split(customLegendBySeparator)[0])),
              ]}
              setSelectedChartComponents={(newState: SetStateAction<string[]>) => {
                const newStateTmp = newState as string[];
                newState = chartComponents.filter((component) => {
                  const name = component.split(customLegendBySeparator)[0];
                  return newStateTmp.includes(name);
                });
                setSelectedChartComponents(newState);
              }}
              componentStyle={Object.entries(legendComponentStyle).reduce(
                (a, b) => ({ ...a, [b[0].split("/")[0]]: b[1] }),
                {}
              )}
              ChartComponents={chartComponents.reduce((a, b) => {
                const name = b.split(customLegendBySeparator)[0];
                return { ...a, [name]: name };
              }, {})}
              className="-mt-1"
              fontWeight={500}
              fontSpanClassName="truncate"
              hasTooltip
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TopKMultiLineChart;
