import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import { getWorkloadType } from "../overviewUtils";
import { FeatureEnabled } from "../../../utils/FeaturesHelper";
import RightArrowIcon from "../../../Icons/RightArrowIcon";
import dayjs from "dayjs";
import Tooltip from "../../Tooltip";
import { Typography } from "@mui/material";
import WarningIcon from "../../../Icons/WarningIcon";
import pluralize from "pluralize";
import WarningPopup from "../../warningPopup/WarningPopup";
import CreativeInfoIcon from "../../../Icons/CreativeInfoIcon";
import WasteIcon from "../../../Icons/WasteIcon";
import DollarIcon from "../../../Icons/DollarIcon";
import { getDisplayWorkloadName } from "../WorkloadStatusByNamespace";
import ExploreYourWorkloadMessage from "../ExploreYourWorkloadMessage";
import CheckIcon from "../../../Icons/CheckIcon";
import ExploreEntityTooltip from "../ExploreEntityTooltip";

const ENABLE_FIRST_ANIMATION_CTA_FF = true;
const FOUR_DAYS_IN_SEC = 4 * 24 * 60 * 60;
const MAX_CLICKS_TO_SHOW_ROW_PULSE = 3;

export interface WorkloadsColumnProps {
  lastTimeWorkloadRowWasClicked: number;
  workloadRowClickCount: number;
  firstRowId?: string;
  page: number;
  hpaOptimizationEnabled: boolean | undefined;
  isWarningTooltipOpen: boolean;
  setIsWarningTooltipOpen: (value: boolean) => void;
}

export default function WorkloadsColumn({
  params,
  firstRowId,
  hpaOptimizationEnabled,
  isWarningTooltipOpen,
  lastTimeWorkloadRowWasClicked,
  page,
  setIsWarningTooltipOpen,
  workloadRowClickCount,
}:
  | {
      params: GridRenderCellParams<
        components["schemas"]["DashIssues"],
        components["schemas"]["UtilsWorkload"],
        components["schemas"]["DashIssues"]
      >;
    } & WorkloadsColumnProps) {
  const userClickOnWorkloadRowAtTheLastFourDays =
    new Date().getTime() / 1000 - lastTimeWorkloadRowWasClicked < FOUR_DAYS_IN_SEC;
  const userClickedMoreThanThreeTimes = Number(workloadRowClickCount) > MAX_CLICKS_TO_SHOW_ROW_PULSE;
  const showClickRowMessage =
    ENABLE_FIRST_ANIMATION_CTA_FF &&
    params.row.isReadyRecommendation &&
    !userClickOnWorkloadRowAtTheLastFourDays &&
    !userClickedMoreThanThreeTimes;
  const showAnimatedPingDote = showClickRowMessage && params.row.id === firstRowId && page === 0;
  const savingAvailable = Math.round(params.row.savingsAvailable * 100) / 100;

  const tooltipType = getWorkloadType(params.row);

  const hasWorkloadErrorsWarning =
    params.row.namespace != "scaleops-system" && params.row.workloadErrors && params.row.workloadErrors?.length > 0;

  const hasOOMWarning = !FeatureEnabled("DemoVersion") &&
    params.row.oomLastTimestamp &&
    params.row.oomLastTimestamp.length > 0 &&
    params.row.oomCountLast24h &&
    params.row.oomCountLast24h > 0 && (
      <div>
        <p className="flex gap-2 items-center">
          <RightArrowIcon width={8} height={8} />
          Out of memory
        </p>
        <ul className="pl-8 list-disc text-[10px]">
          <li>
            <b>Last out of memory:</b> {dayjs.utc(params.row.oomLastTimestamp).format("YYYY-MM-DD HH:mm:ss")}
          </li>
          <li>
            <b>OOM events in the last 24 hours:</b> {params.row.oomCountLast24h}
          </li>
        </ul>
      </div>
    );

  const totalNumberOfWarnings = (params.row.workloadErrors?.length ?? 0) + (hasOOMWarning ? 1 : 0);

  const workloadErrorsWarnings =
    hasWorkloadErrorsWarning || hasOOMWarning ? (
      <Tooltip
        title={
          <div className="flex flex-col gap-4">
            <div>
              <Typography
                variant="body2"
                fontWeight={700}
                className="bg-guideline-lightPurple px-1 py-0.5 rounded-lg flex items-center gap-1 w-full min-w-[200px]"
              >
                <WarningIcon width={14} height={14} /> {pluralize("Warning", totalNumberOfWarnings)}
              </Typography>
              <ul className="pl-4 pt-2">
                {hasOOMWarning ? <li>{hasOOMWarning}</li> : null}
                {params.row.workloadErrors?.map((e) => (
                  <li className="flex gap-2 items-center py-[2px]">
                    <RightArrowIcon width={8} height={8} />
                    <p>{e}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        }
        maxWidth={500}
      >
        <WarningIcon width={14} height={14} />
      </Tooltip>
    ) : null;

  const demoWarning = FeatureEnabled("DemoVersion") && params.value ? <WarningPopup warnings={params.value} /> : null;

  const bufferTimer = dayjs.utc().subtract(15, "seconds").unix();
  const isAutomationTimeOlderThanBuffer = dayjs.utc(params.row.automationTime).unix() < bufferTimer;

  let percentageOfOptimizedReplicas = params.row.replicas
    ? Math.round(((params.row?.optimizedReplicas ?? 0) / params.row.replicas) * 100)
    : 0;
  const percentageOfOptimizedBinPackReplicas = params.row.replicas
    ? Math.round(((params.row?.optimizedBinPackReplicas ?? 0) / params.row.replicas) * 100)
    : 0;

  switch (true) {
    case percentageOfOptimizedReplicas > 100:
      percentageOfOptimizedReplicas = 100;
      break;
    case percentageOfOptimizedReplicas < 0:
      percentageOfOptimizedReplicas = 0;
      break;
    default:
      break;
  }

  const numberOfUnOptimizedPods = (params.row.replicas ?? 0) - (params.row.optimizedReplicas ?? 0);

  const eventReasonWarning =
    isAutomationTimeOlderThanBuffer &&
    params.row.auto &&
    ((params.row.eventReason !== "" && params.row.eventReason !== undefined) || params.row?.rolloutInProgress) &&
    params.row.savingsAvailable &&
    params.row.savingsAvailable !== 0 ? (
      <Tooltip
        title={
          <div>
            {percentageOfOptimizedReplicas > -1 && (
              <b className="text-guideline-darkGreen">{percentageOfOptimizedReplicas}% are optimized</b>
            )}
            {params.row?.rolloutInProgress ? (
              <p className="text-[.625rem] bg-guideline-darkGray rounded-sm px-2">Workload rollout in progress</p>
            ) : (
              <div>
                {!!numberOfUnOptimizedPods && (
                  <p>
                    {numberOfUnOptimizedPods} {pluralize("pod", numberOfUnOptimizedPods)}{" "}
                    {pluralize("is", numberOfUnOptimizedPods)} not optimized due to:
                  </p>
                )}
                {params.row.eventReason && (
                  <p className="italic text-[.625rem] bg-guideline-darkGray px-2 py-3 rounded-sm">
                    {params.row.eventReason}
                  </p>
                )}
                {params.row.eventAction && (
                  <p className="text-[.625rem] bg-guideline-darkGray px-2 pb-3 rounded-sm">
                    <b>{params.row.eventAction}</b>
                  </p>
                )}
              </div>
            )}
          </div>
        }
        maxWidth={500}
      >
        <CreativeInfoIcon width={14} height={14} />
      </Tooltip>
    ) : null;

  const eventReasonWarningBinpack =
    isAutomationTimeOlderThanBuffer &&
    params.row.auto &&
    params.row.eventReason !== "" &&
    params.row.eventReason !== undefined &&
    params.row.shouldBinPack &&
    (params.row.optimizedBinPackReplicas === undefined ||
      params.row.replicas != params.row.optimizedBinPackReplicas) ? (
      <Tooltip
        title={
          <div>
            {percentageOfOptimizedBinPackReplicas > -1 && (
              <b className="text-guideline-darkGreen">
                {percentageOfOptimizedBinPackReplicas}% are binpacked optimized
              </b>
            )}
            {params.row?.rolloutInProgress ? (
              <p className="text-[.625rem] bg-guideline-darkGray rounded-sm px-2">Workload rollout in progress</p>
            ) : (
              <div>
                {!!numberOfUnOptimizedPods && (
                  <p>
                    {numberOfUnOptimizedPods} {pluralize("pod", numberOfUnOptimizedPods)}{" "}
                    {pluralize("is", numberOfUnOptimizedPods)} not optimized due to:
                  </p>
                )}
                {params.row.eventReason && (
                  <p className="italic text-[.625rem] bg-guideline-darkGray px-2 py-3 rounded-sm">
                    {params.row.eventReason}
                  </p>
                )}
                {params.row.eventAction && (
                  <p className="text-[.625rem] bg-guideline-darkGray px-2 pb-3 rounded-sm">
                    <b>{params.row.eventAction}</b>
                  </p>
                )}
              </div>
            )}
          </div>
        }
        maxWidth={500}
      >
        <CreativeInfoIcon width={14} height={14} />
      </Tooltip>
    ) : null;

  const wasterResourcesWarning =
    params.row.wastedResources && params.row.wastedResources.length > 0 ? (
      <Tooltip
        title={
          <div>
            <b>Wasted resources</b>
            <br />
            <Typography variant="caption" className="">
              Not running pods waste resources due to:
            </Typography>
            <ul className="list-disc pl-4">
              {params.row.wastedResources.map((e) => {
                return <li>{e}</li>;
              })}
            </ul>
          </div>
        }
        maxWidth={500}
      >
        <WasteIcon width={17} height={17} />
      </Tooltip>
    ) : null;

  const scaleOutEvent =
    hpaOptimizationEnabled &&
    isAutomationTimeOlderThanBuffer &&
    params.row.scaleOutMessage !== "" &&
    params.row.scaleOutMessage !== undefined ? (
      <Tooltip title={params.row.scaleOutMessage} maxWidth={500}>
        <DollarIcon width={14} height={14} className="mr-1" />
      </Tooltip>
    ) : null;

  const displayWorkloadName = getDisplayWorkloadName(
    params.row.workloadName,
    params.row.hideSuffix,
    params.row.displayName
  );

  const workloadName = `${params.row.namespace}/${displayWorkloadName}`;

  return (
    <Tooltip
      title={<ExploreYourWorkloadMessage />}
      maxWidth={500}
      disabled={!showAnimatedPingDote || isWarningTooltipOpen}
      isTourGuideTooltip
    >
      <div className="flex gap-[.125rem] items-center justify-start w-full relative">
        <div className="w-fit flex gap-[.125rem] items-center justify-start">
          {showAnimatedPingDote && (
            <div className="w-2 h-2 mx-1 relative">
              <div className="animate-ping absolute inline-flex w-2 h-2 rounded-full bg-main-blue opacity-50 left-0 top-0" />
              <div className="absolute inline-flex w-2 h-2 rounded-full bg-main-blue opacity-75 left-0 top-0" />
            </div>
          )}
          {params.row.auto && savingAvailable === 0 && <CheckIcon className="text-main-green" />}
          <div
            className="flex gap-1 items-center"
            onMouseEnter={() => setIsWarningTooltipOpen(true)}
            onMouseLeave={() => setIsWarningTooltipOpen(false)}
          >
            {workloadErrorsWarnings}
            {wasterResourcesWarning}
            {demoWarning}
            {eventReasonWarning}
            {eventReasonWarningBinpack}
            {scaleOutEvent}
          </div>
        </div>
        <div className="relative truncate">
          <ExploreEntityTooltip
            workloadName={displayWorkloadName}
            namespace={params.row.namespace}
            entityName={workloadName}
            entityType={tooltipType}
            disabled={showAnimatedPingDote}
          />
        </div>
      </div>
    </Tooltip>
  );
}
